// Generated by Framer (78a4586)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {PWtYFffte: {hover: true}};

const cycleOrder = ["PWtYFffte"];

const serializationHash = "framer-fAP1s"

const variantClassNames = {PWtYFffte: "framer-v-lw4wm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "PWtYFffte", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lw4wm", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"PWtYFffte"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"PWtYFffte-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fAP1s.framer-caz6fn, .framer-fAP1s .framer-caz6fn { display: block; }", ".framer-fAP1s.framer-lw4wm { cursor: pointer; height: 300px; overflow: hidden; position: relative; width: 200px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 300
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"D08bPKMs5":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerel5kov0c3: React.ComponentType<Props> = withCSS(Component, css, "framer-fAP1s") as typeof Component;
export default Framerel5kov0c3;

Framerel5kov0c3.displayName = "ㅇㅇ";

Framerel5kov0c3.defaultProps = {height: 300, width: 200};

addFonts(Framerel5kov0c3, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})